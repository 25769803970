import React from "react";
import '../scss/global.scss'
import SectionIntro from "./SectionIntro";

export default function IntroVideo() {
    return (
       <section id="introVideo" className={'intro-video'}>
               <div className="container">
                   <SectionIntro>
                       <h2>An introduction</h2>
                       <p>Get to know Performance Energy</p>
                   </SectionIntro>
                   <div className="vimeo-module">
                       {/*<iframe src="https://player.vimeo.com/video/660714203?h=4d64cf6442" width="1920" height="1080"*/}
                       {/*        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"*/}
                       {/*        allowFullScreen></iframe>*/}
                       <iframe
                           src="https://player.vimeo.com/video/671836939?h=cdc44cb8c9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                           frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                           title="Introduction Video - Performance Energy.mp4"></iframe>

                   </div>
               </div>
       </section>
    )
}